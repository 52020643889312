<template>
  <div class="customer-follow">
    <div class="customer-follow-title">客户动态</div>
    <van-field
      v-model="content"
      autosize
      type="textarea"
      maxlength="800"
      placeholder="记录用户所说的重点！"
      show-word-limit
    />
    <!-- rows="2" -->
    <!-- <van-cell center
      <template #title>
        <div>选择客户跟进动作类型</div>
      </template>
      <template #default>
        <van-dropdown-menu active-color="#1989fa">
          <van-dropdown-item v-model="checked" :options="types" />
        </van-dropdown-menu>
      </template>
    </van-cell> -->
    <van-button type="primary" @click="onSubmit" block class="save-btn">
      确定
    </van-button>
  </div>
</template>
<script>
import { Field, DropdownMenu, DropdownItem, Cell, Toast, Button } from 'vant'
import { reactive, toRefs, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { getFollowType, addFlollow } from '@/service/customer.service.js'
import { addGroupDynamic } from '../../../service/group.service'

export default {
  name: 'Follow',
  components: {
    [Field.name]: Field,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Cell.name]: Cell,
    [Button.name]: Button,
    [Toast.name]: Toast
  },
  setup(props) {
    const route = useRoute()
    const router = useRouter()
    const state = reactive({
      content: null,
      checked: null,
      types: []
    })

    // //获取跟进状态
    // const init = async () => {
    //   const { data } = await getFollowType()
    //   state.types = data.map((item) => {
    //       console.log('item', item)
    //     return {
    //       text: item.name,
    //       value: item.id
    //     }
    //   })
    // }

    //提交跟进记录
    const onSubmit = async () => {
      try {
        if (state.content.length === 0) {
          Toast('请记录用户所说的重点！')
          return
        }

        const { type, id } = route.params
        if (type === 'group') {
          await addGroupDynamic({
            chat_id: id,
            content: state.content
          })
        } else {
          await addFlollow({
            external_userid: id,
            followup_type_content: state.content
          })
        }
        Toast.success('确认成功！')
        router.go(-1)
      } catch {
        Toast.fail('确认失败！')
      }
    }

    onMounted(() => {
      //   init()
    })

    return { ...toRefs(state), onSubmit }
  }
}
</script>
<style lang="less" scoped>
.customer-follow {
  width: 100%;
  padding: 24px 14px;
  box-sizing: border-box;
  background-color: #fff;
  .type {
    background-color: #fff;
  }
  .customer-follow-title {
    font-size: 15px;
    font-weight: bold;
    color: #171717;
    margin-bottom: 12px;
  }
  :deep(.van-field) {
    height: 114px;
    padding: 12px 11px;
    border-radius: 4px;
    box-sizing: border-box;
    border: 1px solid #edeef2;
    .van-field__body {
      height: 100%;
    }
    .van-field__control {
      height: 100% !important;
    }
    .van-field__word-limit {
      margin-top: -8px !important;
      line-height: 12px !important;
      color: #999999 !important;
    }
  }
  .save-btn {
    height: 48px;
    line-height: 48px;
    margin: 18px 0 0;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    background: @theme-color;
    border-radius: 6px;
  }
}
</style>
